import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';

export default function Tools() {
  const [loading, setLoading] = React.useState(false);
  const [calendarEventTitle, setCalendarEventTitle] = React.useState(localStorage?.getItem('calendarEventTitle') || 'Title');
  const [location, setLocation] = React.useState(localStorage?.getItem('location') || 'Location');
  const [minutesAhead, setMinutesAhead] = React.useState(localStorage?.getItem('minutesAhead') || '1');
  const [durationMinutes, setDurationMinutes] = React.useState(localStorage?.getItem('durationMinutes') || '30');
  const [numberOfEvents, setnumberOfEvents] = React.useState(localStorage?.getItem('numberOfEvents') || '1');
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit() {
    setLoading(true);
    // @ts-ignore
    if (gapi?.client?.calendar) {
      for (let i = 0; i < Number(numberOfEvents); i++) {
        const event = {
          calendarEventTitle: calendarEventTitle, 
          location: location, 
          minutesAhead: minutesAhead, 
          durationMinutes: durationMinutes
        };
        try {
          await insertEvent(event);
          enqueueSnackbar('Event Inserted', { 
            variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            }
          );
        } catch (e) {
          enqueueSnackbar('Could Not Insert Event', { 
            variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            }
          );
        }
      }
    } else {
      console.log("trying to use the gapi calendar client before it loads");
    }
    setLoading(false);
  }

  const handleTitleChange = (e: any) => setCalendarEventTitle(e.target.value);
  const handleLocationChange = (e: any) => setLocation(e.target.value);
  const handleStartMinuteChange = (e: any) => setMinutesAhead(e.target.value);
  const handleDurationMinutesChange = (e: any) => setDurationMinutes(e.target.value);
  const handleNumberOfEventsChange = (e: any) => setnumberOfEvents(e.target.value);

  return (
      <Box sx={{ '& > button': { m: 1 } }}>
        <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField 
          id="outlined-basic" 
          label="Title" 
          variant="outlined" 
          defaultValue="Title" 
          onChange={handleTitleChange} 
        />
        <TextField 
          id="outlined-basic" 
          label="Location" 
          variant="outlined" 
          defaultValue="Location" 
          onChange={handleLocationChange} 
        />
        <TextField 
          id="outlined-basic" 
          label="Starting in" 
          type="number"
          variant="outlined" 
          defaultValue="1" 
          onChange={handleStartMinuteChange} 
          InputProps={{
            endAdornment: <InputAdornment position="end">min</InputAdornment>
          }}
        />
        <TextField 
          id="outlined-basic" 
          label="Duration" 
          type="number"
          variant="outlined" 
          defaultValue="30" 
          onChange={handleDurationMinutesChange} 
          InputProps={{
            endAdornment: <InputAdornment position="end">min</InputAdornment>
          }}
        />
        <TextField 
          id="outlined-basic" 
          label="Munber of events" 
          type="number"
          variant="outlined" 
          defaultValue="1" 
          onChange={handleNumberOfEventsChange} 
        />
      </Box>
        <LoadingButton
          onClick={handleSubmit}
          startIcon={<AddCircleOutlineIcon />}
          loading={loading}
          loadingPosition="start"
          variant="contained"
        >
          Add Calendar Event
        </LoadingButton>
      </Box>
  );
}

async function insertEvent({calendarEventTitle, location, minutesAhead, durationMinutes}: {calendarEventTitle?: any, location?: any, minutesAhead?: any, durationMinutes?: any}){
  const eventStartTime = new Date();
  eventStartTime.setMinutes(eventStartTime.getMinutes() + Number(minutesAhead));

  const eventEndTime = new Date();
  eventEndTime.setMinutes(eventEndTime.getMinutes() + Number(durationMinutes) + Number(minutesAhead));

  const calendarEvent = {
    summary: calendarEventTitle,
    description: 'This event was generated using calendar.raguirre.dev',
    start: {
        dateTime: eventStartTime,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    end: {
        dateTime: eventEndTime,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    location: location,
    ColorId: 1,
  }
  // @ts-ignore
  await gapi.client.calendar.events.insert(
    {
        calendarId: 'primary', 
        resource: calendarEvent
    }, 
    (err: any, res: any) => {
      if (err) return console.error('Calendar Event Insertion Error: ', err);
      return console.log('Calendar Event Inserted: ', res);
    }
  )
}

// async function getEvents() {
//   // @ts-ignore
//   const events = await gapi.client?.calendar.events.list({
//     calendarId: 'primary', 
//     timeMin: new Date().toISOString(),
//     showDeleted: false,
//     singleEvents: true,
//     maxResults: 10,
//     orderBy: 'startTime'
//   });
//   console.log(events);
// }
