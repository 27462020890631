import { Avatar, Card, CardContent, Container, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { getAuth } from "@firebase/auth";
import Tools from "../../components/Inserter";
import { useEffect } from "react";

export default function Widget() {
  useEffect(() => {
    // 1. Load the JavaScript client library.
  gapi.load('client', start);
  async function start() {
    // 2. Initialize the JavaScript client library.
    await gapi.client.init({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      // clientId and scope are optional if auth is not required.
      clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
      scope: 'https://www.googleapis.com/auth/calendar',
    });
  };
  gapi.client.load('calendar', 'v3', () => {
    console.log('Loaded calendar');
  });
  }, []);
  
  const auth = getAuth();

  return (
    <Container component="main" maxWidth="xs" sx={{textAlign: 'center'}}>
      <Card>
        <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Insert Calendar Events
        </Typography>
        <List dense={true}>
          <ListItem>
              <ListItemIcon>
                <Avatar alt="avatar" src={auth.currentUser?.photoURL as string} />
              </ListItemIcon>
              <ListItemText
                primary={auth.currentUser?.email}
              />
            </ListItem>
        </List>
        <Tools />
        </CardContent>
      </Card>
    </Container>
  );
}
