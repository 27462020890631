import { useContext, useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import SignIn from './SignIn';
import './App.css';
import Header from './Header/index';
import {FirebaseContext} from '../contexts/FirebaseContext';

import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline';
import Main from '../layouts/Home';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { PrivacyPolicy } from '../layouts/PrivacyPolicy';
import { SnackbarProvider } from 'notistack';

// 1. Load the JavaScript client library.
gapi.load('client', start);

async function start() {
  // 2. Initialize the JavaScript client library.
  await gapi.client.init({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    scope: 'https://www.googleapis.com/auth/calendar',
  }).then(() => {
    console.log('gapi client loaded');
  });
};

export default function App() {
  const {auth} = useContext(FirebaseContext);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
        createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            light: '#757ce8',
            main: '#3f50b5',
            dark: '#002884',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
          },
        },
      }),
    [prefersDarkMode],
  );

  const [user] = useAuthState(auth);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline/>
          <div className="App">
          <Header />
          <Router>
            <Switch>
              <Route exact path="/">
                {user? <Main /> : <SignIn />}
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
            </Switch>
          </Router>
          </div> 
      </SnackbarProvider>
    </ThemeProvider>
  );
}
