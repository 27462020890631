import {createContext} from 'react';
import { initializeApp } from "firebase/app";
import {Firestore, getFirestore} from 'firebase/firestore';
import {Auth, getAuth} from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth();

export interface IFirebaseContext {
    firebase: any,
    auth: Auth,
    firestore: Firestore
}

export const FirebaseContext = createContext({} as any);

export const FirebaseProvider = ({ children }: any) => {
    return (
        <FirebaseContext.Provider value={{ firebase: app, auth: auth, firestore: firestore} as IFirebaseContext}>
            {children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseContext;