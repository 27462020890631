import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { GoogleAuthProvider } from "firebase/auth";

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/system';
import {getAuth, signInWithCredential} from 'firebase/auth';

export default function SignIn() {
    const theme = useTheme();

    const signInWithGoogleGapi = async () => {
        const googleAuth = gapi.auth2.getAuthInstance();
        const googleUser = await googleAuth.signIn();

        const authResponse = googleUser.getAuthResponse();
        const credential = GoogleAuthProvider.credential( authResponse.id_token, authResponse.access_token);
        signInWithCredential(getAuth(), credential);
    }

    return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
        <Avatar sx={{
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        }}>
            <LockOutlinedIcon />
        </Avatar>
        <br />
        <Button color="primary" variant="contained" onClick={signInWithGoogleGapi}>
            Sign in with Google
        </Button>
        </Box>
    </Container>
    );
}